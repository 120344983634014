<template>
  <div v-if="treeIsRendered && hoveredNode">
    <div class="node-hover-card">
      <b-button
        variant="flat-secondary"
        class="popup-close-btn"
        @click.stop="dismissPopup"
      >
        <feather-icon icon="XIcon" size="16" />
      </b-button>

      <b-list-group style="font-size: 9pt">
        <div class="px-1 pt-1">
          <span class="text-primary d-block font-weight-bolder">Multiplicity</span>{{ hoveredNode.link_label }}
          <span class="text-primary d-block font-weight-bolder mt-50">Lineage</span>
          <span>{{ hoveredNode.display_name }} is</span>
          <div v-for="a in ancestors" :key="a.id">
            {{a.relType}}
            <b-link @click="routeToComponent(a.id)">{{ a.display_name }}</b-link>
          </div>
        </div>

        <ListGroupItemEntity
          :auto-expand-delay="600"
          :component="hoveredNode"
          :show-ontology-button="false"
          hover-mode
        />
      </b-list-group>
    </div>
  </div>
</template>

<script setup>
import ListGroupItemEntity from '@/components/Forms/ListGroupItems/ListGroupItemEntity.vue'
import { OntologyNode } from '@/components/Domain/OntologyTreeViewJoint/ontologyNode'
import { nextTick, ref, computed } from '@vue/composition-api'
import {
  shapes,
  ui,
  util,
  layout,
} from '@clientio/rappid'
import router from '@/router'
import store from '@/store'

export default {
  name: 'OntologyHoverPopup',
  components: {
    ListGroupItemEntity,
  },
  props: {
    treeIsRendered: {
      type: Boolean,
      default: null,
    },
    paper: {
      type: Object,
      required: true,
    },
    scroller: {
      type: Object,
      required: true,
    },
    nodeMap: {
      type: Object,
      required: true,
    },
    tree: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { paper, scroller, nodeMap, tree } = props
    const hoveredNode = ref(null) // OntologyNode object
    const treeData = computed(() => props.tree) // wait for compositionTree to be filled
    const ancestors = computed(() => {
      if (!treeData.value.edges) return []
      const results = []
      let { pid } = hoveredNode.value
      while (pid) {
        results.push(nodeMap[pid])
        pid = nodeMap[pid].pid
      }
      return results
        .filter(n => n.display_name)
        .map(n => ({
          ...n,
          relType: treeData.value.edges?.find(e => e.target === n.id)?.rel_type === 'HAS_PART' ? ' a part of ' : ' a type of ',
        }))
    })

    const HOVER_DELAY_MS = computed(() => store.state.preferences.ontologyPopupDelayMs)
    const showPopup = computed(() => store.state.preferences.ontologyPopupShow)
    let hoverDelayTimeoutId = null

    async function showHoverNodePopup(cellView, evt) {
      evt.preventDefault()
      if (hoveredNode.value && hoveredNode.value.id === cellView.model.id) {
        return
      }
      if (nodeMap[cellView.model.id]?.isHeader) {
        return
      }
      clearTimeout(hoverDelayTimeoutId)
      hoveredNode.value = null
      ui.Popup.close()
      await nextTick()
      hoveredNode.value = nodeMap[cellView.model.id]
      new ui.Popup({
        content: '',
        autoClose: false,
        target: cellView.el,
      }).render()
      await nextTick()
      const popups = document.getElementsByClassName('joint-popup')
      const popupEle = popups[popups.length - 1]
      const ele = document.getElementsByClassName('node-hover-card')[0]
      popupEle.prepend(ele)
    }

    paper.on('element:mouseenter', (cellView, evt) => {
      if (!showPopup.value) {
        ui.Popup.close()
        clearTimeout(hoverDelayTimeoutId)
        return
      }
      hoverDelayTimeoutId = setTimeout(() => showHoverNodePopup(cellView, evt), HOVER_DELAY_MS.value)
    })
    paper.on('element:mouseleave', (cellView, evt) => {
      // If the user doesn't hover for long enough, cancel the timeout that would show the popup
      evt.preventDefault()
      clearTimeout(hoverDelayTimeoutId)
    })
    paper.on('blank:pointerclick', evt => {
      evt.preventDefault()
      clearTimeout(hoverDelayTimeoutId)
      hoveredNode.value = null
      ui.Popup.close()
    })
    scroller.on('scroll', (evt, dx, dy) => {
      evt.preventDefault()
      if (hoveredNode.value) {
        // need to manually alert the popup that the pan position has changed
        ui.Popup.update()
      }
    })

    function routeToComponent(componentId) {
      router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: componentId },
        },
      )
    }

    function dismissPopup() {
      hoveredNode.value = null
      ui.Popup.close()
      clearTimeout(hoverDelayTimeoutId)
    }

    return {
      hoveredNode,
      ancestors,
      dismissPopup,
      routeToComponent,
    }
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu';

.node-hover-card {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 80vw;
  max-width: 40vw;
  min-width: 25rem;
  background-color: white;
  padding: 0.25rem;
  border-radius: 3px;
  border-color: lightgrey;
  box-shadow: 1px 1px 10px #1e1e1e0e;
}

body.dark-layout ~ .joint-popup.joint-theme-default {
  background-color: transparent;
  border-color: transparent;
  --arrow-color: #{$theme-dark-input-bg};
  --arrow-mask-color: #{$theme-dark-input-bg};

  .node-hover-card {
    color: #aab0c6;
    background-color: $theme-dark-input-bg;
    box-shadow: 1px 1px 10px black;
  }
}
.popup-close-btn {
  position: absolute;
  right: 0;
}
</style>
