<template>
  <b-modal
    id="ontology-preferences-modal"
    title="Preferences - Ontology"
    size="l"
    ok-title="Save"
    ok-variant="success"
    cancel-variant="outline-secondary"
    lazy
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    @ok="onSubmit"
    @show="onShow"
    @hidden="onHidden"
  >
    <b-form>
      <b-form-group
        label-cols-lg="3"
        label="Ontology"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <b-form-group label-cols="8" label-align="right" label="Show popup on hover">
          <b-form-checkbox v-model="showPopup"></b-form-checkbox>
        </b-form-group>
        <b-form-group label-cols="8" label-align="right" label="Popup delay (ms)">
          <b-form-input v-model="popupDelayMs" :debounce="500"></b-form-input>
        </b-form-group>
      </b-form-group>
    </b-form>
  </b-modal>
</template>
<script setup>
import { computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

export default {
  name: 'OntologyPreferencesModal',
  setup(props, context) {
    const { paper, scroller, nodeMap, tree } = props
    const showPopup = computed({
      get() {
        return store.state.preferences.ontologyPopupShow
      },
      set(newValue) {
        store.dispatch('preferences/ontologyPopupShow', newValue)
      },
    })
    const popupDelayMs = computed({
      get() {
        return store.state.preferences.ontologyPopupDelayMs
      },
      set(newValue) {
        store.dispatch('preferences/ontologyPopupDelayMs', newValue)
      },
    })

    function routeToComponent(componentId) {
      router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: componentId },
        },
      )
    }

    function dismissPopup() {
    }

    const onSubmit = () => {
    }
    const onShow = () => {
      store.dispatch('preferences/loadAll')
    }
    const onHidden = () => {
    }
    return {
      onShow,
      onHidden,
      onSubmit,
      popupDelayMs,
      showPopup,
    }
  },
}
</script>
<style lang="scss">

</style>
